<template>
    <div
        class="field field-text"
        :class="{
            'is-invalid': !!errorMessage,
            'field-underlined': underlined,
            'is-readonly': readonly,
            'is-disabled': disabled,
        }">
        <div v-if="!formFloating && label" class="hstack justify-content-between">
            <label class="form-label" :for="idAttr">{{ label }}<span v-if="mandatory" class="text-danger ms-1">*</span></label>
            <slot name="label-end"></slot>
        </div>
        <div
            class="form-control-wrapper"
            :class="{ 
                'has-icon-start': $slots['icon-start'],
                'form-floating': formFloating
            }">
            <div v-if="$slots['icon-start']" class="icon-start">
                <slot name="icon-start"></slot>
            </div>
            <div v-if="inputGroupText" class="input-group">
                <span v-if="inputGroupTextPlacement === 'start'" class="input-group-text">{{ inputGroupText }}</span>
                <component 
                    :is="as"
                    :id="idAttr" 
                    :type="type === 'password' && canRevealPwd ? 'text' : type" 
                    :autocomplete="autocomplete"
                    :name="name"
                    class="form-control"
                    :class="{
                        'is-invalid': !!errorMessage,
                        'pe-5': type === 'password'
                    }"
                    :placeholder="placeholder || (formFloating ? label : '')"
                    :value="inputValue"
                    :disabled="disabled"
                    :readonly="readonly"
                    v-textarea-auto-grow="(as === 'textarea')"
                    @input="handleChange"
                    @blur="handleBlur"
                    :maxlength="maxlength"
                    :minlength="minlength"
                    :step="step"
                    :rows="as === 'textarea' ? rows : undefined"
                    :autofocus="autofocus"
                    :min="min"
                    :max="max">
                </component>
                <span v-if="inputGroupTextPlacement === 'end'" class="input-group-text">{{ inputGroupText }}</span>
            </div>
            <component
                v-else 
                :is="as"
                :id="idAttr" 
                :type="type === 'password' && canRevealPwd ? 'text' : type" 
                :autocomplete="autocomplete"
                :name="name"
                class="form-control"
                :class="{
                    'is-invalid': !!errorMessage,
                    'pe-5': type === 'password'
                }"
                :placeholder="placeholder || (formFloating ? label : '')"
                :value="inputValue"
                :disabled="disabled"
                :readonly="readonly"
                v-textarea-auto-grow="(as === 'textarea')"
                @input="handleChange"
                @blur="handleBlur"
                :maxlength="maxlength"
                :minlength="minlength"
                :step="step"
                :rows="as === 'textarea' ? rows : undefined"
                :autofocus="autofocus"
                :min="min"
                :max="max">
            </component>
            <slot name="cta-slot"></slot>
            <label v-if="formFloating && label" :for="idAttr">{{ label }}</label>
            <button v-if="type === 'password'"
                class="btn-reveal"
                @click.prevent="toggleReveal"
                tabindex="-1"
                type="button">
                <Icon v-if="canRevealPwd" symbol="eye-off"></Icon>
                <Icon v-else symbol="eye"></Icon>
            </button>
        </div>
        <p v-if="hint" class="hint-message caption text-muted mt-1">
            <slot name="hint-prefix"></slot>
            {{ hint }}</p>
        <p v-show="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
        <p v-if="!hideMaxLengthHint && !!maxlength" class="text-muted mt-2"><small>{{ charactersLeft }} characters left</small></p>
    </div>
</template>

<script lang="ts" setup>
import { ref, PropType, computed } from "vue";
import { useField } from "vee-validate";
import { Icon } from "@/modules/core/components";
import { TextareaAutoGrow as vTextareaAutoGrow } from "@/modules/core/directives";
import kebabCase from 'lodash/kebabCase';

const props = defineProps({
    label: String,
    id: String,
    autocomplete: String,
    placeholder: String,
    type: {
        type: String,
        default: "text"
    },
    name: {
        type: String,
        required: true
    },
    value: String,
    disabled: Boolean,
    readonly: Boolean,
    as: {
        type: String as PropType<'input'|'textarea'>,
        default: 'input'
    },
    rows: {
        type: Number,
        default: 2
    },
    minlength: Number,
    maxlength: Number,
    hideMaxLengthHint: Boolean,
    underlined: Boolean,
    hint: String,
    mandatory: Boolean,
    autofocus: Boolean,
    step: Number,
    formFloating: Boolean,
    min: Number,
    max: Number,
    inputGroupText: String,
    inputGroupTextPlacement: {
        type: String as PropType<'start'|'end'>,
        default: 'start'
    },
})

const idAttr = computed(() => props.id ? props.id : 'txt-' + kebabCase(props.name));

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(props.name, undefined, {
    initialValue: props.value,
});

const canRevealPwd = ref(props.type === 'password' ? false : true);
const toggleReveal = () => canRevealPwd.value = !canRevealPwd.value;
const charactersLeft = computed(() => Math.max((props.maxlength ?? 0) - (inputValue.value?.length ?? 0), 0));
</script>

<style lang="scss">

.field-text {
    .btn-reveal {
        position: absolute;
        border: 0;
        background-color: transparent;
        padding: 0;
        right: $input-btn-padding-x;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        color: $gray-600;
    }

    .icon-start,
    .icon-end {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .icon-start { left: .75rem; }
    .icon-end { right: .75rem; }

    .form-control-wrapper.has-icon-start {
        .form-control {
            padding-inline-start: 2.5rem;
        }

        label {
            padding-inline-start: 2.75rem;
        }
        .icon-start,
        .icon-end {
            z-index: 3;
        }
    }
}

</style>